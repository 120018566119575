article {
    :first-child {
        margin-top: 0 !important;
    }

    p {
        line-height: 1.9; 
    }

    .figure-fluid-article {
        max-width: 100%;
        display: flex; 
        flex-direction: column;   
        margin: 0 auto;
        align-items: center;
        justify-content: center;
    }
    .figure-fluid-article img {
        max-width: 100%;
        max-height: 720px;
        display: flex;
        height: auto;
        margin: 0 auto;
    }

    .bg-svg {
        background-color: #FFF;
    }
}